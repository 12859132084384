import logo from "./logo.svg";
import "./App.css";
import Deck from "./components/Deck/Deck";
import { useState } from "react";
import StartScreen from "./views/StartScreen";

function App() {
  const [isInPile, setIsInPile] = useState(false);
  const [cutCards, setCutCards] = useState([]);
  const [numSelectedPiles, setNumSelectedPiles] = useState(0);
  const [isOnStartScreen, setIsOnStartScreen] = useState(true);
  const [deckColor, setDeckColor] = useState(null);
  const [isOnTable, setIsOnTable] = useState(false);

  let cardHoverSound = new Audio("./sounds/shuffle-loud.mp3");
  const playHoverSound = () => {
    cardHoverSound.play();
  };

  let flipUpSound = new Audio("./sounds/flipup.mp3");
  const playFlipUpSound = () => flipUpSound.play();

  let flipDownSound = new Audio("./sounds/flipdown.mp3");
  const playFlipDownSound = () => flipDownSound.play();

  let cutSound = new Audio("./sounds/cut-stack.mp3");
  const playCutSound = () => cutSound.play();

  // let reverseSound = new Audio("./sounds/reverse.mp3");
  const playReverseSound = () => cutSound.play();

  let tapStackSound = new Audio("./sounds/tap-stack.mp3");
  const playTapStackSound = () => tapStackSound.play();

  return (
    <div className={`App isOnTable-${isOnTable}`}>
      <div className="background" style={{ zIndex: "-1" }} />

      {isOnStartScreen ? (
        <StartScreen
          setIsOnStartScreen={setIsOnStartScreen}
          setDeckColor={setDeckColor}
        />
      ) : (
        <div>
          <Deck
            deckColor={deckColor}
            cutCards={cutCards}
            setCutCards={setCutCards}
            isInPile={isInPile}
            numSelectedPiles={numSelectedPiles}
            setNumSelectedPiles={setNumSelectedPiles}
            playHoverSound={playHoverSound}
            playFlipUpSound={playFlipUpSound}
            playFlipDownSound={playFlipDownSound}
            playReverseSound={playReverseSound}
            playTapStackSound={playTapStackSound}
          />

          <img
            src={`${isInPile ? "img/fan-button.png" : "img/cut-button.png"}`}
            className={`button ${
              cutCards.length > 0 && numSelectedPiles === 0
                ? "visible"
                : "hidden"
            }`}
            onClick={() => {
              if (!isInPile) {
                playCutSound();
              } else {
                playReverseSound();
              }
              setIsInPile(!isInPile);
            }}
            alt={`${isInPile ? "Spread out button" : "Cut deck button"}`}
          />
          <img
            id="instruction-text"
            src={`${isInPile ? "img/stack-text.png" : "img/tap-cards.png"}`}
            alt={`${
              isInPile
                ? "Tap piles to choose a stack order"
                : "Tap 1-3 cards to place a cut"
            }`}
          />

          <img
            src="img/stack-and-fan-button.png"
            alt="fan button - MERGE"
            className={`button ${
              isInPile && numSelectedPiles === cutCards.length + 1
                ? "visible"
                : "hidden"
            }`}
            onClick={() => {
              playReverseSound();
              setNumSelectedPiles(0);
              setCutCards([]);
              setNumSelectedPiles(0);
              setIsInPile(!isInPile);
            }}
          />
        </div>
      )}
      <div id="top-line" className="line" />
      <img
        id="session-in-progress"
        alt="session in progress"
        src="img/session-in-progress.png"
      />
      <img id="leave-session" alt="leave session" src="img/leave-session.png" />
      <img
        id="face1"
        className="face"
        alt="face 1"
        src="img/emptyprofile.png"
      />
      <img
        id="face2"
        className="face"
        alt="face 2"
        src="img/emptyprofile.png"
      />
      <img
        id="face3"
        className="face"
        alt="face 3"
        src="img/emptyprofile.png"
      />
      <img
        id="face4"
        className="face"
        alt="face 4"
        src="img/emptyprofile.png"
      />
      {/* <img id="music-text" alt="music player" src="img/music-text.png" /> */}
      <div id="bottom-bar" />
      {deckColor !== null && (
        <img
          id="table-text"
          alt="when you feel ready place on table"
          src="img/table-text.png"
          style={{ cursor: "pointer" }}
          onClick={() => setIsOnTable(true)}
        />
      )}
      {/* <img
        id="fullscreen-text"
        alt="fullscreen button"
        src="img/fullscreen-text.png"
      /> */}

      <div id="bottom-line" className="line" />
    </div>
  );
}

export default App;
