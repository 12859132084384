import styles from "./StartScreen.module.css";
function StartScreen({ setIsOnStartScreen, setDeckColor }) {
  const DECK_COLORS = ["#373737", "#B6D7C9", "#DEBBBB"];
  const DECK_IMGS = ["black-deck.png", "green-deck.png", "pink-deck.png"];
  return (
    <div className={styles.startScreenContainer}>
      <div className={styles.startScreen}>
        <img
          className={styles.chooseDeckText}
          alt="choose a deck"
          src={"img/choose-a-deck.png"}
        />
        <div className={styles.decks}>
          {DECK_COLORS.map((color, i) => (
            <img
              key={i}
              onClick={() => {
                setDeckColor(color);
                setIsOnStartScreen(false);
              }}
              className={styles.deck}
              src={`img/${DECK_IMGS[i]}`}
              alt={"a tarot deck"}
            ></img>
          ))}
        </div>
      </div>
    </div>
  );
}
export default StartScreen;
