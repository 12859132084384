import styles from "./Card.module.css";
import { useEffect, useState } from "react";

function Card({
  x,
  y,
  i,
  cutCards,
  setCutCards,
  isInPile,
  numSelectedPiles,
  setNumSelectedPiles,
  playFlipDownSound,
  playFlipUpSound,
  playReverseSound,
  deckColor,
  playTapStackSound,
  // playHoverSound,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const [displayNumber, setDisplayNumber] = useState(null);
  const coinFlip = () => Math.random() < 0.5;
  const randomSkew = () => {
    console.log("rotate(" + Math.floor(Math.random() * 4 - 2) + "deg)");
  };
  const [isTopCard, setIsTopCard] = useState(false);
  let cardHoverSound = new Audio("./sounds/shuffle-echo.mp3");
  const playHoverSound = () => {
    cardHoverSound.play();
  };
  useEffect(() => {
    const isTC = isInPile && (cutCards.includes(i) || i === 77);
    setIsTopCard(isTC);
  }, [cutCards, isInPile]);

  useEffect(() => {
    if (!isInPile) setIsSelected(false);
    setDisplayNumber(null);
  }, [isInPile]);

  return (
    <div
      onClick={() => {
        if (!isInPile) {
          if (cutCards.length < 3 && !isSelected) {
            setIsSelected(true);
            setCutCards([...cutCards, i].sort());
            playFlipDownSound();
          } else {
            if (isSelected) {
              setIsSelected(false);
              setCutCards(cutCards.filter((card) => card !== i).sort());
              playFlipUpSound();
            }
          }
        } else if (
          isTopCard &&
          displayNumber === null &&
          displayNumber <= cutCards.length + 1
        ) {
          playTapStackSound();
          setDisplayNumber(numSelectedPiles + 1);
          setNumSelectedPiles(numSelectedPiles + 1);
        }
      }}
      className={`${styles.cardContainer} ${
        isSelected ? styles.selected : styles.notSelected
      } ${isInPile ? styles.inPile : ""}`}
    >
      <div
        onMouseEnter={playHoverSound}
        className={`${styles.card} ${
          isSelected && !isInPile ? styles.selected : styles.notSelected
        } ${isInPile ? styles.inPile : ""} ${isTopCard ? styles.topCard : ""}`}
        style={{
          top: `${y}px`,
          left: `${x}px`,
          backgroundColor: deckColor,
        }}
      >
        {displayNumber && isInPile && (
          <img
            className={styles.displayNumber}
            src={`img/number${displayNumber}.png`}
            alt={displayNumber}
            style={{ bottom: `${-60 + y}px` }}
          />
        )}
      </div>
    </div>
  );
}

export default Card;
