import styles from "./Deck.module.css";
import Card from "../Card/Card";
import { useState, useEffect } from "react";

const NUM_CARDS = 78;
const SPACING = 14;
const DECK_WIDTH = 1150;
const CARD_WIDTH = 270;
const Y_SPACING = -1;

const cardStartingPositions = [];
for (let i = 0; i < NUM_CARDS; i++) {
  cardStartingPositions.push({ x: i * SPACING, y: 0 });
}

function Deck({
  isInPile,
  cutCards,
  setCutCards,
  numSelectedPiles,
  setNumSelectedPiles,
  playHoverSound,
  playFlipDownSound,
  playFlipUpSound,
  playReverseSound,
  playTapStackSound,
  deckColor,
}) {
  const [cardPositions, setCardPositions] = useState(cardStartingPositions);

  useEffect(() => {
    let newCardPositions = [];
    cardPositions.forEach(({ x, y }, i) => {
      if (!isInPile) {
        newCardPositions.push(cardStartingPositions[i]);
      } else {
        let x = 0;
        let y = 0;
        if (cutCards.length === 1) {
          // 2 piles
          if (i <= cutCards[0]) {
            x = DECK_WIDTH / 2 - CARD_WIDTH / 2;
            y = i * Y_SPACING;
          } else {
            x = DECK_WIDTH / 2 + CARD_WIDTH / 2;
            y = (i - cutCards[0]) * Y_SPACING;
          }
        } else if (cutCards.length === 2) {
          // 3 piles
          if (i <= cutCards[0]) {
            x = DECK_WIDTH / 3 - CARD_WIDTH / 3;
            y = i * Y_SPACING;
          } else if (i > cutCards[0] && i <= cutCards[1]) {
            x = DECK_WIDTH / 2;
            y = (i - cutCards[0]) * Y_SPACING;
          } else if (i > cutCards[1]) {
            x = (2 * DECK_WIDTH) / 3 + CARD_WIDTH / 3;
            y = (i - cutCards[1]) * Y_SPACING;
          }
        } else if (cutCards.length === 3) {
          // 4 piles
          if (i <= cutCards[0]) {
            x = DECK_WIDTH / 4 - CARD_WIDTH / 2;
            y = i * Y_SPACING;
          } else if (i > cutCards[0] && i <= cutCards[1]) {
            x = DECK_WIDTH / 2 - CARD_WIDTH / 2;
            y = (i - cutCards[0]) * Y_SPACING;
          } else if (i > cutCards[1] && i <= cutCards[2]) {
            x = DECK_WIDTH / 2 + CARD_WIDTH / 2;
            y = (i - cutCards[1]) * Y_SPACING;
          } else if (i > cutCards[2]) {
            x = (3 * DECK_WIDTH) / 4 + CARD_WIDTH / 2;
            y = (i - cutCards[2]) * Y_SPACING;
          }
        }
        newCardPositions.push({ x, y });
      }
    });
    setCardPositions(newCardPositions);
  }, [isInPile, cutCards]);

  useEffect(() => {}, [cutCards]);

  return (
    <div className={styles.deck}>
      {cardPositions.map(({ x, y }, i) => (
        <Card
          key={i}
          x={x}
          y={y}
          cutCards={cutCards}
          setCutCards={setCutCards}
          i={i}
          isInPile={isInPile}
          numSelectedPiles={numSelectedPiles}
          setNumSelectedPiles={setNumSelectedPiles}
          playHoverSound={playHoverSound}
          playFlipUpSound={playFlipUpSound}
          playFlipDownSound={playFlipDownSound}
          playReverseSound={playReverseSound}
          deckColor={deckColor}
          playTapStackSound={playTapStackSound}
        />
      ))}
    </div>
  );
}

export default Deck;
